import React, { useState, useEffect, useRef } from "react";
import styles from "./Gallery.module.scss";
import Button from "../common/Button";
import { ArrowRightIcon } from "@heroicons/react/20/solid";
import { Link } from "gatsby";
import { PostCard } from "../home/BestSellers";

const Gallery = ({ artworks }) => {
  return (
    <div className={styles.section}>
      <div className={styles.content}>
        <div className={styles.grid}>
          {artworks.map((post) => (
            <PostCard
              id={post.id}
              title={post.frontmatter.title}
              type={post.frontmatter.type}
              slug={post.fields.slug}
              images={post.frontmatter.images}
              defaultSize={post.frontmatter.defaultSize}
              soldOut={post.frontmatter.soldOut}
              prices={post.frontmatter.prices}
              discount={post.frontmatter.discount}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default Gallery;
